import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES } from "../components/seo";
import { translations } from "../locales/communion.js";
import background from "../assets/img/eventsTypes/communion.svg";
import EventsTypes from "../components/eventsTypes";

const Communion = () => {
  return (
    <Layout page="communion" language="es">
      <main className="container">
        <EventsTypes
          background={background}
          text={translations.es.text}
          title={translations.es.title}
          textButton={translations.es.button}
          language={"es"}
        />
      </main>
    </Layout>
  );
};

export default Communion;

export const Head = () => <SeoES title="Invitaciones digitales para Comunión Online | Diseña tus invitaciones con Kolibird" description="Diseña invitaciones digitales personalizadas para la comunión de tus hijos con Kolibird. Una alternativa ecológica y práctica para invitar a tus familiares y amigos. Regístrate ahora en Kolibird y comienza a crear tus invitaciones digitales de comunión." hrefLangs={[{href: "https://kolibird.app/invitaciones-digitales-comunion/", lang: "es"}, {href: "https://kolibird.app/en/digital-communion-invitations/", lang: "en"}]}/>;